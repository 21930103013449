<template>
  <centered-layout>
    <div>
      <div class="primary-heading">Oops! Something went wrong</div>
      <div class="caption disabled">
        We couldnot connect to our server. Please try again later
      </div>
      <div
        class="text-center primary-text"
        @click="$router.go(-1)"
        style="margin-top: 1rem; padding: 0.5rem; font-weight: 600; font-size: 1.25rem; cursor: pointer;"
      >
        Reload
      </div>
    </div>
  </centered-layout>
</template>

<script>
  import CenteredLayout from "@/components/layouts/CenteredLayout.vue";

  export default {
    name: "NetworkError",

    components: { CenteredLayout },
  };
</script>

<style></style>
